<template>
  <div class="usercomp">
    <el-row>
      <el-col :span="24">
        <div class="search">
          <el-form :inline="true" class="demo-form-inline">

            <el-form-item label="省份">
              <el-select v-model="provincevalue" @change="provinceChange" placeholder="请选择">
                <el-option
                    v-for="item in provinceselect"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="城市">
              <el-select v-model="cityvalue" placeholder="请选择">
                <el-option
                    v-for="item in cityselect"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="账号类型">
              <el-select v-model="idtvalue" placeholder="请选择">
                <el-option
                    v-for="item in idtselect"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="    ">
              <el-input clearable placeholder="输入用户名" v-model="searchinput">
                <template slot="prepend">查找用户:</template>
                <el-button slot="append" @click="onRefresh" icon="el-icon-search">查询</el-button>
              </el-input>
            </el-form-item>

          </el-form>

        </div>
        <div class="search">


        </div>

        <el-table
            :data="tbdata"
            border
            stripe
            height="660"
            style="width: 100%; margin: 3px"
        >
<!--          <el-table-column-->
<!--              prop="id"-->
<!--              align="center"-->
<!--              label="用户ID"-->
<!--              width="185"-->
<!--          >-->
<!--          </el-table-column>-->
          <el-table-column prop="name" label="用户名" align="center">
          </el-table-column>

          <el-table-column prop="pg" width="100" label="帐号类型" align="center">
          </el-table-column>

          <!-- <el-table-column prop="avail_times" width="100" label="可分配次数" align="center">
          </el-table-column> -->

          <el-table-column prop="phone" width="120" label="联系方式" align="center">
          </el-table-column>

          <el-table-column prop="prov" width="80" label="省份" align="center">
          </el-table-column>

          <el-table-column prop="city" width="80" label="城市" align="center">
          </el-table-column>

          <el-table-column prop="addr" label="地址" align="center">
          </el-table-column>

          <el-table-column prop="login_time" width="180" label="登录时间" align="center">
          </el-table-column>

          <el-table-column width="400">
            <template slot="header">
              <el-button
                  type="success"
                  icon="el-icon-user"
                  style="margin-left: 30px;width: 140px"
                  @click="CreateNewUser"
              >增加用户
              </el-button
              >
              <el-button
                  type="primary"
                  icon="el-icon-refresh"
                  style="margin-left: 30px;width: 140px"
                  @click="onRefresh"
              >刷新列表
              </el-button
              >
            </template>
            <template slot-scope="scope">
              <el-button size="mini" plain @click="OpenModifyDialog(scope.$index)">修改资料</el-button>
              <el-button size="mini" plain
                         @click="ChangePasswordDialog = true;InputPassword1='';InputPassword2='';ChangePasswordSN=tbdata[scope.$index].uid">
                修改密码
              </el-button>

              

              <!-- <span v-if="pg_list.charge_user">
                <el-divider direction="vertical"></el-divider>
                <el-button size="mini" type="success" @click="AllocTimesCommit(scope.$index)" plain>分配次数</el-button>
              </span> -->

              <span v-if="pg_list.delete_user">
                <el-divider direction="vertical"></el-divider>
                <el-button size="mini" type="danger" @click="DeleteUser(scope.$index)" plain>删除用户</el-button>
              </span>


            </template>
          </el-table-column>
        </el-table>
      </el-col>
      <!--      .........................-->


    </el-row>

    <!-- 修改密码 Start -->
    <el-dialog title="修改密码" :visible.sync="ChangePasswordDialog" width="500px">
                <el-form>
                  <el-form-item label="新密码" label-width="80px">
                    <el-input v-model="InputPassword1" show-password autocomplete="off" maxlength="32"></el-input>
                  </el-form-item>
                  <el-form-item label="确认密码" label-width="80px">
                    <el-input v-model="InputPassword2" show-password autocomplete="off" maxlength="32"></el-input>
                  </el-form-item>
                </el-form>
                <div slot="footer" class="dialog-footer">
                  <el-button @click="ChangePasswordDialog = false">取 消</el-button>
                  <el-button type="primary" @click="ChangePassword">确 定</el-button>
                </div>
              </el-dialog>
              <!-- 修改密码 END -->

              <!-- 创建用户 Start -->
              <el-dialog title="增加用户" :visible.sync="CreateUserDialog" width="500px">
                <el-form>
                  <el-form-item label="用户名：" label-width="100px" required>
                    <el-input clearable v-model="InputCreateUsername" autocomplete="off" maxlength="16"
                              show-word-limit></el-input>
                  </el-form-item>
                  <el-form-item label="初始密码：" label-width="100px" required>
                    <el-input v-model="InputCreatePassword" show-password autocomplete="off" maxlength="32"></el-input>
                  </el-form-item>

                  <el-form-item label="用户权限：" label-width="100px" required>
                    <el-select v-model="CreateNext" placeholder="请选择">
                      <el-option
                          v-for="item in idtselect"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value">
                      </el-option>
                    </el-select>
                  </el-form-item>


                </el-form>
                <div slot="footer" class="dialog-footer">
                  <el-button @click="CreateUserDialog = false">取 消</el-button>
                  <el-button type="primary" @click="CreateUserCommit">确 定</el-button>
                </div>
              </el-dialog>
              <!-- 创建用户 END -->

              <!-- 修改用户资料 Start -->
              <el-dialog title="修改用户资料" :visible.sync="modifyUserInfoDialog" width="500px">
                <el-form>
                  <el-form-item label="用户名：" label-width="100px" required>
                    <el-input clearable v-model="modifyName" autocomplete="off" maxlength="16"
                              show-word-limit></el-input>
                  </el-form-item>
                  <el-form-item label="电话号码：" label-width="100px">
                    <el-input clearable v-model="modifyPhone" autocomplete="off" maxlength="16"
                              show-word-limit></el-input>
                  </el-form-item>
                  <el-form-item label="电子邮箱：" label-width="100px">
                    <el-input clearable v-model="modifyEmail" autocomplete="off" maxlength="64"
                              show-word-limit></el-input>
                  </el-form-item>
                  <el-form-item label="省份：" label-width="100px">
                    <el-select v-model="modifyProv" @change="modifyprovinceChange" placeholder="请选择">
                      <el-option
                          v-for="item in modifyProvinceselect"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value">
                      </el-option>
                    </el-select>
                  </el-form-item>

                  <el-form-item label="城市：" label-width="100px">
                    <el-select v-model="modifyCity" placeholder="请选择">
                      <el-option
                          v-for="item in modifyCityselect"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value">
                      </el-option>
                    </el-select>
                  </el-form-item>

                  <el-form-item label="详细地址：" label-width="100px">
                    <el-input
                        clearable
                        type="textarea"
                        placeholder="请输入地址"
                        v-model="modifyAddr"
                        maxlength="80"
                        show-word-limit
                    >
                    </el-input>
                  </el-form-item>

                </el-form>
                <div slot="footer" class="dialog-footer">
                  <el-button @click="modifyUserInfoDialog = false">取 消</el-button>
                  <el-button type="primary" @click="ModifyUserinfoCommit">确 定</el-button>
                </div>
              </el-dialog>
              <!-- 修改用户资料 END -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      searchinput: "",
      tbdata: [],
      provinceselect: [{"value": "", "label": "不限"}, {"value": "北京市", "label": "北京市"}, {
        "value": "天津市",
        "label": "天津市"
      }, {"value": "河南省", "label": "河南省"}, {"value": "浙江省", "label": "浙江省"}, {
        "value": "福建省",
        "label": "福建省"
      }, {
        "value": "河北省",
        "label": "河北省"
      }, {"value": "山西省", "label": "山西省"}, {"value": "内蒙古自治区", "label": "内蒙古自治区"}, {
        "value": "辽宁省",
        "label": "辽宁省"
      }, {"value": "吉林省", "label": "吉林省"}, {"value": "黑龙江省", "label": "黑龙江省"}, {
        "value": "上海市",
        "label": "上海市"
      }, {"value": "江苏省", "label": "江苏省"}, {"value": "安徽省", "label": "安徽省"}, {
        "value": "江西省",
        "label": "江西省"
      }, {"value": "山东省", "label": "山东省"}, {
        "value": "湖北省",
        "label": "湖北省"
      }, {"value": "湖南省", "label": "湖南省"}, {"value": "广东省", "label": "广东省"}, {
        "value": "广西壮族自治区",
        "label": "广西壮族自治区"
      }, {"value": "海南省", "label": "海南省"}, {"value": "重庆市", "label": "重庆市"}, {
        "value": "四川省",
        "label": "四川省"
      }, {"value": "贵州省", "label": "贵州省"}, {"value": "云南省", "label": "云南省"}, {
        "value": "西藏自治区",
        "label": "西藏自治区"
      }, {"value": "陕西省", "label": "陕西省"}, {"value": "甘肃省", "label": "甘肃省"}, {
        "value": "青海省",
        "label": "青海省"
      }, {"value": "宁夏回族自治区", "label": "宁夏回族自治区"}, {
        "value": "新疆维吾尔自治区",
        "label": "新疆维吾尔自治区"
      }],
      provincevalue: '',
      cityselect: [{
        value: '',
        label: '不限'
      }],
      cityvalue: '',
      idtselect: [],
      idtvalue: '',

      citydb: {
        "北京市": ["东城区", "西城区", "朝阳区", "丰台区", "石景山区", "海淀区", "门头沟区", "房山区", "通州区", "顺义区", "昌平区", "大兴区", "怀柔区", "平谷区", "密云区", "延庆区"],
        "天津市": ["和平区", "河东区", "河西区", "南开区", "河北区", "红桥区", "东丽区", "西青区", "津南区", "北辰区", "武清区", "宝坻区", "滨海新区", "宁河区", "静海区", "蓟州区"],
        "河北省": ["石家庄市","雄安新区", "唐山市", "秦皇岛市", "邯郸市", "邢台市", "保定市", "张家口市", "承德市", "沧州市", "廊坊市", "衡水市"],
        "山西省": ["太原市", "大同市", "阳泉市", "长治市", "晋城市", "朔州市", "晋中市", "运城市", "忻州市", "临汾市", "吕梁市"],
        "内蒙古自治区": ["呼和浩特市", "包头市", "乌海市", "赤峰市", "通辽市", "鄂尔多斯市", "呼伦贝尔市", "巴彦淖尔市", "乌兰察布市", "兴安盟", "锡林郭勒盟", "阿拉善盟"],
        "辽宁省": ["沈阳市", "大连市", "鞍山市", "抚顺市", "本溪市", "丹东市", "锦州市", "营口市", "阜新市", "辽阳市", "盘锦市", "铁岭市", "朝阳市", "葫芦岛市"],
        "吉林省": ["长春市", "吉林市", "四平市", "辽源市", "通化市", "白山市", "松原市", "白城市", "延边朝鲜族自治州"],
        "黑龙江省": ["哈尔滨市", "齐齐哈尔市", "鸡西市", "鹤岗市", "双鸭山市", "大庆市", "伊春市", "佳木斯市", "七台河市", "牡丹江市", "黑河市", "绥化市", "大兴安岭地区"],
        "上海市": ["黄浦区", "徐汇区", "长宁区", "静安区", "普陀区", "虹口区", "杨浦区", "闵行区", "宝山区", "嘉定区", "浦东新区", "金山区", "松江区", "青浦区", "奉贤区", "崇明区"],
        "江苏省": ["南京市", "无锡市", "徐州市", "常州市", "苏州市", "南通市", "连云港市", "淮安市", "盐城市", "扬州市", "镇江市", "泰州市", "宿迁市"],
        "浙江省": ["杭州市", "台州市", "宁波市", "温州市", "嘉兴市", "湖州市", "绍兴市", "金华市", "衢州市", "舟山市", "丽水市"],
        "安徽省": ["合肥市", "芜湖市", "蚌埠市", "淮南市", "马鞍山市", "淮北市", "铜陵市", "安庆市", "黄山市", "滁州市", "阜阳市", "宿州市", "六安市", "亳州市", "池州市", "宣城市"],
        "福建省": ["福州市", "厦门市", "莆田市", "三明市", "泉州市", "漳州市", "南平市", "龙岩市", "宁德市"],
        "江西省": ["南昌市", "抚州市", "景德镇市", "萍乡市", "九江市", "新余市", "鹰潭市", "赣州市", "吉安市", "宜春市", "上饶市"],
        "山东省": ["济南市", "青岛市", "淄博市", "枣庄市", "东营市", "烟台市", "潍坊市", "济宁市", "泰安市", "威海市", "日照市", "临沂市", "德州市", "聊城市", "滨州市", "菏泽市"],
        "河南省": ["南阳市", "洛阳市", "郑州市", "开封市", "平顶山市", "安阳市", "鹤壁市", "新乡市", "焦作市", "濮阳市", "许昌市", "漯河市", "三门峡市", "商丘市", "信阳市", "周口市", "驻马店市", "济源市"],
        "湖北省": ["武汉市", "黄石市", "十堰市", "宜昌市", "襄阳市", "鄂州市", "荆门市", "孝感市", "荆州市", "黄冈市", "咸宁市", "随州市", "恩施土家族苗族自治州", "仙桃市", "潜江市", "天门市", "神农架林区"],
        "湖南省": ["长沙市", "株洲市", "湘潭市", "衡阳市", "邵阳市", "岳阳市", "常德市", "张家界市", "益阳市", "郴州市", "永州市", "怀化市", "娄底市", "湘西土家族苗族自治州"],
        "广东省": ["广州市", "韶关市", "深圳市", "珠海市", "汕头市", "佛山市", "江门市", "湛江市", "茂名市", "肇庆市", "惠州市", "梅州市", "汕尾市", "河源市", "阳江市", "清远市", "东莞市", "中山市", "潮州市", "揭阳市", "云浮市"],
        "广西壮族自治区": ["南宁市", "柳州市", "桂林市", "梧州市", "北海市", "防城港市", "钦州市", "贵港市", "玉林市", "百色市", "贺州市", "河池市", "来宾市", "崇左市"],
        "海南省": ["海口市", "三亚市", "三沙市", "儋州市", "五指山市", "琼海市", "文昌市", "万宁市", "东方市", "定安县", "屯昌县", "澄迈县", "临高县", "白沙黎族自治县", "昌江黎族自治县", "乐东黎族自治县", "陵水黎族自治县", "保亭黎族苗族自治县", "琼中黎族苗族自治县"],
        "重庆市": ["万州区", "涪陵区", "渝中区", "大渡口区", "江北区", "沙坪坝区", "九龙坡区", "南岸区", "北碚区", "綦江区", "大足区", "渝北区", "巴南区", "黔江区", "长寿区", "江津区", "合川区", "永川区", "南川区", "璧山区", "铜梁区", "潼南区", "荣昌区", "开州区", "梁平区", "武隆区", "城口县", "丰都县", "垫江县", "忠县", "云阳县", "奉节县", "巫山县", "巫溪县", "石柱土家族自治县", "秀山土家族苗族自治县", "酉阳土家族苗族自治县", "彭水苗族土家族自治县"],
        "四川省": ["成都市", "自贡市", "攀枝花市", "泸州市", "德阳市", "绵阳市", "广元市", "遂宁市", "内江市", "乐山市", "南充市", "眉山市", "宜宾市", "广安市", "达州市", "雅安市", "巴中市", "资阳市", "阿坝藏族羌族自治州", "甘孜藏族自治州", "凉山彝族自治州"],
        "贵州省": ["贵阳市", "六盘水市", "遵义市", "安顺市", "毕节市", "铜仁市", "黔西南布依族苗族自治州", "黔东南苗族侗族自治州", "黔南布依族苗族自治州"],
        "云南省": ["昆明市", "曲靖市", "玉溪市", "保山市", "昭通市", "丽江市", "普洱市", "临沧市", "楚雄彝族自治州", "红河哈尼族彝族自治州", "文山壮族苗族自治州", "西双版纳傣族自治州", "大理白族自治州", "德宏傣族景颇族自治州", "怒江傈僳族自治州", "迪庆藏族自治州"],
        "西藏自治区": ["拉萨市", "日喀则市", "昌都市", "林芝市", "山南市", "那曲市", "阿里地区"],
        "陕西省": ["西安市", "铜川市", "宝鸡市", "咸阳市", "渭南市", "延安市", "汉中市", "榆林市", "安康市", "商洛市"],
        "甘肃省": ["兰州市", "嘉峪关市", "金昌市", "白银市", "天水市", "武威市", "张掖市", "平凉市", "酒泉市", "庆阳市", "定西市", "陇南市", "临夏回族自治州", "甘南藏族自治州"],
        "青海省": ["西宁市", "海东市", "海北藏族自治州", "黄南藏族自治州", "海南藏族自治州", "果洛藏族自治州", "玉树藏族自治州", "海西蒙古族藏族自治州"],
        "宁夏回族自治区": ["银川市", "石嘴山市", "吴忠市", "固原市", "中卫市"],
        "新疆维吾尔自治区": ["乌鲁木齐市", "克拉玛依市", "吐鲁番市", "哈密市", "昌吉回族自治州", "博尔塔拉蒙古自治州", "巴音郭楞蒙古自治州", "阿克苏地区", "克孜勒苏柯尔克孜自治州", "喀什地区", "和田地区", "伊犁哈萨克自治州", "塔城地区", "阿勒泰地区", "石河子市", "阿拉尔市", "图木舒克市", "五家渠市", "北屯市", "铁门关市", "双河市", "可克达拉市", "昆玉市", "胡杨河市"]
      },
      // 用户资料
      modifyUserInfoDialog: false,
      modifyID: "",
      modifyName: "",
      modifyPhone: "",
      modifyEmail:"",
      modifyProv: "",
      modifyProvinceselect: [{"value": "", "label": "选择省份"}, {"value": "北京市", "label": "北京市"}, {"value": "河南省", "label": "河南省"}, {"value": "浙江省", "label": "浙江省"}, {
        "value": "天津市",
        "label": "天津市"
      }, {"value": "河北省","label": "河北省"}, {"value": "山西省", "label": "山西省"}, {"value": "内蒙古自治区", "label": "内蒙古自治区"}, {
        "value": "辽宁省",
        "label": "辽宁省"
      }, {"value": "吉林省", "label": "吉林省"}, {"value": "黑龙江省", "label": "黑龙江省"}, {
        "value": "上海市",
        "label": "上海市"
      }, {"value": "江苏省", "label": "江苏省"}, {"value": "安徽省", "label": "安徽省"}, {
        "value": "福建省",
        "label": "福建省"
      }, {"value": "江西省", "label": "江西省"}, {"value": "山东省", "label": "山东省"}, {
        "value": "湖北省",
        "label": "湖北省"
      }, {"value": "湖南省", "label": "湖南省"}, {"value": "广东省", "label": "广东省"}, {
        "value": "广西壮族自治区",
        "label": "广西壮族自治区"
      }, {"value": "海南省", "label": "海南省"}, {"value": "重庆市", "label": "重庆市"}, {
        "value": "四川省",
        "label": "四川省"
      }, {"value": "贵州省", "label": "贵州省"}, {"value": "云南省", "label": "云南省"}, {
        "value": "西藏自治区",
        "label": "西藏自治区"
      }, {"value": "陕西省", "label": "陕西省"}, {"value": "甘肃省", "label": "甘肃省"}, {
        "value": "青海省",
        "label": "青海省"
      }, {"value": "宁夏回族自治区", "label": "宁夏回族自治区"}, {
        "value": "新疆维吾尔自治区",
        "label": "新疆维吾尔自治区"
      }],
      modifyCity: "",
      modifyCityselect: [{
        value: '',
        label: '选择城市'
      }],
      modifyAddr: "",
      //
      ChangePasswordSN: "",
      ChangePasswordDialog: false,
      InputPassword1: "",
      InputPassword2: "",

      CreateUserDialog:false,
      InputCreateUsername:"",
      InputCreatePassword:"",
      newidtselect: [],
      CreateNext:"",

      pg_list: {
        delete_user: false,
        charge_user:false,
      },
    }
  },
  created() {
    this.info = this.$store.state.userInfo

    this.onRefresh();
    this.PG_Query();
  },
  mounted() {

  },
  beforeDestroy() {
    //this.closeTimer()
  },
  methods: {
    // 修改密码
    async ChangePassword() {
      if (this.InputPassword1 === this.InputPassword2) {
        let res = await this.$api.change_password({
          uid: ""+this.ChangePasswordSN,
          pw: ""+this.InputPassword1,
        })
        console.log(res)
        if (res.status == 1) {
          this.$message({
            message: '密码修改成功',
            type: 'success',
          })
          this.ChangePasswordDialog = false
          this.onRefresh();
        }
      } else {
        this.$notify({
          title: '错误',
          message: "两次输入密码不一致",
          type: 'error',
        })
      }

    },
    async AllocTimesCommit(index) {
      this.$prompt('请输入分配次数：', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(async ({value}) => {
        let res = await this.$api.charge_user({
          uid: this.tbdata[index].uid,
          times: value,
        })
        console.log(res)
        if (res.status == 1) {
          this.$message({
            message: '分配成功',
            type: 'success',
          })
          this.onRefresh();
        }
      });

    },
    // 修改用户信息
    OpenModifyDialog(index) {
      this.modifyID = this.tbdata[index].uid
      this.modifyName = this.tbdata[index].name
      this.modifyPhone = this.tbdata[index].phone
      this.modifyEmail = this.tbdata[index].email
      this.modifyProv = this.tbdata[index].prov
      if (this.modifyProv != "") {
        this.modifyCityselect = [{
          value: '',
          label: '选择城市'
        }]
        for (const c of this.citydb[this.modifyProv]) {
          console.log(c)
          this.modifyCityselect.push({
            value: c,
            label: c
          })
        }
      }
      this.modifyCity = this.tbdata[index].city
      this.modifyAddr = this.tbdata[index].addr
      this.modifyUserInfoDialog = true
    },

    async ModifyUserinfoCommit() {
      let res = await this.$api.update_user_info({
        uid: this.modifyID,
        name: this.modifyName,
        phone: this.modifyPhone,
        prov: this.modifyProv,
        city: this.modifyCity,
        email:this.modifyEmail,
        addr: this.modifyAddr
      })
      console.log(res)
      if (res.status == 1) {
        this.$message({
          message: '修改成功',
          type: 'success',
        })
        this.onRefresh();
      }
      this.modifyUserInfoDialog = false
    },
    /// 新建用户  -----------------------------------------------------------------------------------------------------------

    async DeleteUser(index) {
      this.$confirm('确认删除用户:"' + this.tbdata[index].name + '"？', '提示', {
        confirmButtonText: '确认删除',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        let res = await this.$api.delete_user({
          uid: this.tbdata[index].uid
        })
        if (res.status == 1) {
          this.$message({
            message: '删除成功',
            type: 'success',
          })

          this.onRefresh()
        }
      }).catch(() => {
        console.log("cancel")
      });

    },
    async CreateNewUser() {
      this.InputCreateUsername = ""
      this.InputCreatePassword = ""
      this.CreateNext = ""
      this.CreateUserDialog = true
    },
    async CreateUserCommit() {
      if(this.CreateNext == "")
      {
          this.$notify({
            title: '错误',
            message: "请选择新用户所属权限组",
            type: 'error',
          })
         return
      }

      let res = await this.$api.create_user({
        username:""+this.InputCreateUsername,
        password:""+this.InputCreatePassword,
        next:""+this.CreateNext
      })
      console.log(res)
      if (res.status == 1) {
        this.$confirm('用户名:"' + res.name + '"   初始密码:"' + res.pw + '"    请尽快设置资料和修改初始密码!', '新用户已创建', {
          confirmButtonText: '设置资料',
          cancelButtonText: '取消',
          type: 'success'
        }).then(() => {
          this.modifyID = res.uid
          this.modifyName = res.name
          this.modifyPhone = ""
          this.modifyProv = ""
          this.modifyCity = ""
          this.modifyAddr = ""
          this.modifyUserInfoDialog = true
        }).catch(() => {
          console.log("cancel")
        });
        this.onRefresh()
        this.CreateUserDialog = false
      }

    },

    modifyprovinceChange(value) {
      if (value == "") {
        this.modifyCityselect = [{
          value: '',
          label: '选择城市'
        }]
      } else {
        this.modifyCityselect = [{
          value: '',
          label: '选择城市'
        }]
        for (const c of this.citydb[value]) {
          console.log(c)
          this.modifyCityselect.push({
            value: c,
            label: c
          })
        }
      }
      this.modifyCity = ""
    },
    provinceChange(value) {
      console.log(value)
      if (value == "") {
        this.cityselect = [{
          value: '',
          label: '不限'
        }]
        this.cityvalue = ""
      } else {
        this.cityselect = [{
          value: '',
          label: '不限'
        }]
        for (const c of this.citydb[value]) {
          console.log(c)
          this.cityselect.push({
            value: c,
            label: c
          })
        }

        this.cityvalue = ""
      }

    },


    async onRefresh() {
      let res = await this.$api.user_list({
        name: this.searchinput,
        province: this.provincevalue,
        city: this.cityvalue,
        pg: this.idtvalue
      })
      console.log(res)
      if (res.status == 1) {
        this.tbdata = res.data

        for (var i = 0; i < res.data.length; i++) {
          if (res.data[i].avail_times < 0) {
            res.data[i].avail_times = "∞"
          }

        }

      }
    },
    async PG_Query() {
      let res = await this.$api.pg_query({
        pg: this.pg_list,
      })

      if (res.status == 1) {
        this.pg_list = res.data
      }
      res = await this.$api.pg_list()
      if (res.status == 1) {
        this.idtselect = res.data
        this.newidtselect = res.data
        this.newidtselect[0].label = "请选择"
      }

    },

  },
}
</script>

<style scoped>
.search {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.detail-card .el-button {
  margin: 8px;
  margin-bottom: 14px;
  font-size: 16px;
  width: 92%
}

</style>
